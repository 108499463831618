import Skin from './skin.js'
import Interscroller from './interscroller.js'
import Native from './native.js'
import Slideup from './slideup.js'
import Vignette from './vignette.js'
import Responsive from './responsive.js'
import { cpexLog, gamGetElementByPath } from '../utils.js'

/**
 * Class that handles custom format rendering
 */
export default class Formats {
  constructor (main) {
    this.main = main
  }

  /**
   * This method checks if the input corresponds to any of our custom ad formats
   */
  match (elementId, width, height, bidderCode) {
    let ad = false
    if (this.isSkin(elementId, width, height)) { ad = 'skin' }
    if (this.isInterscroller(elementId, width, height)) { ad = 'interscroller' }
    if (this.isNative(width, height, bidderCode)) { ad = 'native' }
    if (this.isVignette(elementId)) { ad = 'vignette' }
    if (this.isSlideup(elementId)) { ad = 'slideup' }
    if (this.isResponsive(elementId, width, height)) { ad = 'responsive' }
    return ad
  }

  isSkin (elementId, width, height) {
    const isSize = (width === 2000 && height === 1400) || (width === 1800 && height === 1000) || (width === 2000 && height === 1200) || (width === 1920 && height === 1080)
    // Everything in adUnitsForced will be caught as a skin
    return this.main.settings.formats.skin?.adUnitsForced ? this.main.settings.formats.skin?.adUnitsForced.includes(elementId) : isSize
  }

  isInterscroller (elementId, width, height) {
    const isSize = (width === 120 && height === 600) || (width === 160 && height === 600) || (width === 300 && height === 600) || (width === 480 && height === 820) || (width === 720 && height === 1280) || (width === 768 && height === 1230)
    return this.main.settings.formats.interscroller?.adUnits ? this.main.settings.formats.interscroller?.adUnits.includes(elementId) && isSize : isSize
  }

  isNative (width, height, bidderCode) { return (width === 548 && height === 150) || (width === 0 && height === 0 && bidderCode === 'smart') }
  isVignette (elementId) { return this.main.settings.formats.vignette?.adUnits?.includes(elementId) }
  isSlideup (elementId) { return this.main.settings.formats.slideup?.adUnits?.includes(elementId) }
  isResponsive (elementId, width, height) {
    return this.main.settings.formats.responsive?.adUnits?.includes(elementId) &&
      this.main.settings.formats.responsive.width === width &&
      this.main.settings.formats.responsive.height === height
  }

  create (type, elementId, width, height, adUnit) {
    switch (type) {
      case 'skin': return new Skin(elementId, this.main.settings, width, height)
      case 'native': return new Native(elementId, this.main.settings, width, height, adUnit)
      case 'slideup': return new Slideup(elementId, this.main.settings, width, height)
      case 'vignette': return new Vignette(elementId, this.main.settings, width, height)
      case 'interscroller': return new Interscroller(elementId, this.main.settings, width, height)
      case 'responsive': return new Responsive(elementId, this.main.settings, width, height)
    }
  }

  /**
   * Used for Demand Manager catching. Requires googletag to be present.
   */
  dmMatchAndPrepare (adUnitPath, width, height) {
    const slot = gamGetElementByPath(adUnitPath)
    const elementId = slot.getSlotElementId()
    const customFormat = this.match(elementId, width, height)
    return customFormat ? this.prepareIframe(customFormat, elementId, width, height, null, adUnitPath) : false
  }

  prepareIframe (type, elementId, width, height, attributes, adUnit) {
    cpexLog('Formats: Rendering CPEx custom format: ' + type)
    const ad = this.create(type, elementId, width, height, adUnit)
    this.main.customAds[elementId] = ad // add to custom ads
    window.dispatchEvent(new window.Event('cpexCustomFormatAdded', { detail: ad }))
    if (this.main.regularAds[elementId]) { delete this.main.regularAds[elementId] } // remove from regular ads
    ad.iframe = ad.prepareIframe(width, height)
    Object.assign(ad.iframe, attributes)
    return ad.iframe
  }
}
