import { cpexLog, cpexError, displayMetaData, isObject, awaitDOM } from '../utils.js'
import AdServerGoogleTag from './googletag.js'
import AdServerSasTracker from './sastracker.js'

/**
 * This adapter returns sample ads immediately, based on keywords in adunit
 */
export default class AdServerTest {
  constructor (main) {
    this.main = main
    this.adapter = 'test'
    this.creatives = {
      banner: "<img src='https://playground.cpex.cz/creatives/square.jpg'>",
      interscroller: "<img src='https://playground.cpex.cz/creatives/interscroller.jpg'>",
      skin: "<img src='https://playground.cpex.cz/creatives/skin.jpg'>",
      native: `<script>
        window.postMessage({
          clickTag: 'https://www.cpex.cz',
          creativeType: 'native',
          data: 'ewogICJuYXRpdmUiOiB7CiAgICAidmVyIjogIjEuMiIsCiAgICAibGluayI6IHsKICAgICAgInVybCI6ICJodHRwczovL3d3dy5jcGV4LmN6LyIKICAgIH0sCiAgICAiYXNzZXRzIjogWwogICAgewogICAgICAicmVxdWlyZWQiOiAxLAogICAgICAidGl0bGUiOiB7CiAgICAgICAgInRleHQiOiAiQ1BFeCBOYXRpdmUgRXhhbXBsZSBBZCBIZWFkbGluZSIsCiAgICAgICAgImxlbiI6IDMxCiAgICAgIH0KICAgIH0sIHsKICAgICAgImRhdGEiOiB7CiAgICAgICAgInR5cGUiOiAyLAogICAgICAgICJ2YWx1ZSI6ICJCYXNlZCBvbiBpYWIgT3BlblJUQiBEeW5hbWljIE5hdGl2ZSBBZHMgQVBJIDEuMiBzcGVjaWZpY2F0aW9uLCBmb3IgYnJvYWQgcmV1c2FiaWxpdHkuIgogICAgICB9CiAgICB9LCB7CiAgICAgICJpbWciOiB7CiAgICAgICAgInR5cGUiOiAzLAogICAgICAgICJ1cmwiOiAiaHR0cHM6Ly9wbGF5Z3JvdW5kLmNwZXguY3ovY3JlYXRpdmVzL25hdGl2ZS5qcGciLAogICAgICAgICJ3IjogNDAwLAogICAgICAgICJoIjogMzAwCiAgICAgIH0KICAgIH1dCiAgfQp9'
        }, '*')
      </script>`,
      slideup: "<img src='https://playground.cpex.cz/creatives/billboard.jpg'>"
    }
  }

  /**
   * Mandatory. Initializes an adserver based on settings.
   */
  load () {
    cpexLog('Adserver: Test adapter loading')
    // Turn off headerbidding, not needed
    this.main.settings.headerbidding.enabled = false
    // Use real adapters to provide prereqisite loading
    if (window.AdsObject || window.googletag) {
      const tempAdapter = window.AdsObject ? new AdServerSasTracker(this.main) : new AdServerGoogleTag(this.main)
      this.loading = tempAdapter.load()
        .catch(e => cpexError('TempAdapter failed to load', e))
        .then(() => { cpexLog('Adserver: Test adapter loaded') })
      return this.loading
    } else {
      cpexError('Adserver: Cannot find SAS or GAM adUnits')
    }
  }

  /**
   * Mandatory. Calls the adserver to get the final ads selected and rendered
   */
  async call () {
    this.skinFound = false
    if (window.googletag) { // In case of GAM setup we have to wait for slots to be able to access DOM
      await awaitDOM()
    }
    await this.loading
    cpexLog('Adserver: Called Test adserver')
    // Delay feature
    if (this.main.settings.adserver.delayCall) {
      cpexLog('AdServer: Adserver call delayed by ' + this.main.settings.adserver.delayCall)
      await new Promise(resolve => setTimeout(resolve, this.main.settings.adserver.delayCall)).catch(e => cpexError('Delay timeout failed', e))
    }
    // Calls render for all ads, trying to figure out where to draw which example ad
    if (window.AdsObject) {
      // SAS setup
      for (const key in window.AdsObject) {
        if (key === 'ball') { continue }
        this.testRender(window.AdsObject[key].area)
      }
    } else if (window.googletag) {
      // GAM setup
      window.googletag.pubads().getSlots().forEach(slot => {
        const adUnitId = slot.getSlotElementId()
        this.slots[slot.getAdUnitPath()] = adUnitId
        this.testRender(adUnitId)
      })
    } else {
      cpexError('Adserver: Cannot find SAS or GAM setup. Have to get adUnits from somewhere')
    }
  }

  /**
   * Prepares an object with useful information for debubbing
   */
  showMetaData (adUnitArea, metaData) {
    setTimeout(() => { // Leave Native ads time to clear content of the target element
      const creativeMetaData = { adapter: 'test', area: adUnitArea, size: metaData.width + 'x' + metaData.height }
      if (this.main.customAds[adUnitArea]) { creativeMetaData.customType = this.main.customAds[adUnitArea].type } // custom format
      displayMetaData(adUnitArea, creativeMetaData)
    }, 500)
  }

  /**
   * Helper, calls main render and metadata function here
   * Had special name to avoid being used to render real ads on some publisher setups
   */
  testRender (adUnitName) {
    // Helper to call render and show metadata with the same params
    const draw = (adUnitId, creative, width, height) => {
      this.main.renderAny(adUnitId, creative, width, height)
      this.showMetaData(adUnitId, { width, height })
    }
    // Look for it in the Test object in format settings
    if (typeof this.main.settings.formats !== 'undefined' && isObject(this.main.settings.formats.testCreatives) && isObject(this.main.settings.formats.testCreatives[adUnitName])) {
      const testCreative = this.main.settings.formats.testCreatives[adUnitName]
      // Check if preset is specified
      if (testCreative.preset && this.creatives[testCreative.preset]) {
        draw(adUnitName, this.creatives[testCreative.preset], testCreative.width, testCreative.height)
      // Check if HTML is specified
      } else if (testCreative.html) {
        draw(adUnitName, testCreative.html, testCreative.width, testCreative.height)
      }
    } else {
      // Fallback to common names
      if (adUnitName.indexOf('rect') > -1 || adUnitName.indexOf('square') > -1) {
        draw(adUnitName, this.creatives.banner, 300, 300)
      } else if (adUnitName.indexOf('interscroller') > -1) {
        draw(adUnitName, this.creatives.interscroller, 480, 820)
      } else if ((adUnitName.indexOf('leaderboard') > -1 || adUnitName.indexOf('skin') > -1) && this.skinFound === false) {
        draw(adUnitName, this.creatives.skin, 2000, 1400)
        this.skinFound = true // Prevents multiple skins being drawn
      } else if (adUnitName.indexOf('native') > -1) {
        draw(adUnitName, this.creatives.native, 548, 150)
      } else if (adUnitName.indexOf('slideup') > -1) {
        draw(adUnitName, this.creatives.slideup, 970, 250)
      } else if (adUnitName.indexOf('vignette') > -1) {
        draw(adUnitName, this.creatives.native, 300, 300)
      }
    }
  }

  // Do nothing for the regular render methods, test adserver shouln't draw real ads
  render (adUnit, creative, width, height) {} // for SAS
  gamReRender (slotPath) {} // for GAM
}
