
export function style () {
  return `
    body.subscriptionModalOpen {
      overflow: hidden !important;
    }
    #cpexSubs_veil {
      z-index: 99999;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0px;
      left: 0px;
      background-color: #0005;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
    }

    #cpexSubs_modalWrapper {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 999999;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    #cpexSubs_modal {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 900px;
      min-height: 100px;
      max-height: 95%;
      box-sizing: border-box;
      margin-top: 20px;
      border-radius: 10px;
      box-shadow: 0px 1px 6px #0002;
      font-family: Helvetica, Calibri, Arial, sans-serif;
      background-color: #FFF;
      overflow: hidden;
    }
      #cpexSubs_content {
        position: relative;
        flex-grow: 1;
        overflow: auto;
        padding-top: 40px;
      }
      #cpexSubs_modal::before,
      #cpexSubs_modal::after {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 100px;
        z-index: 1;
        pointer-events: none;
      }
      #cpexSubs_modal::before {
        top: 0;
        background: linear-gradient(to bottom, white, transparent);
      }
      #cpexSubs_modal::after {
        bottom: 0;
        background: linear-gradient(to top, white, transparent);
      }
      #cpexSubs_modal.hideTop::before { display: none }
      #cpexSubs_modal.hideBottom::after { display: none }

      #cpexSubs_content a {
        color: #263238;
      }

      #cpexSubs_primaryLogo {
        display: block;
        margin: auto;
      }
      #cpexSubs_secondaryLogo {
        position: absolute;
        top: 30px;
        right: 30px;
      }
      #cpexSubs_headline {
        margin-top: 40px;
        margin-bottom: 0px;
        padding: 0px 20px;
        font-size: 12pt;
        font-weight: normal;
        text-align: center;
      }
      #cpexSubs_modal main {
        display: flex;
        gap: 40px;
        padding: 20px 40px;
        padding-bottom: 60px;
        background-color: #FFF;
      }
        #cpexSubs_modal main p {
          font-size: 11pt;
          overflow: auto;
          line-height: 140%;
          margin: 15px 0px;
          height: 12em;
        }
        #cpexSubs_modal main div {
          flex: 1;
        }
          #cpexSubs_modal main div h2 {
            color: #333;
          }
      
      #cpexSubs_cmpButtons, #cpexSubs_subButtons {
        display: flex;
        align-items: center;
      }

      #cpexSubs_modal main button {
        font-size: 14pt;
        padding: 15px 30px;
        cursor: pointer;
        margin-right: 20px;
        border-radius: 3px;
      }

        #cpexSubs_consentButton {
          background-color: ${window.Didomi.getConfig().theme.color};
          border: none;
          color: white;
        }
        #cpexSubs_subscribeButton {
          background-color: #EEE;
          border: 1px #AAA solid;
          color: black;
        }

      #cpexSubs_linkPurposes, #cpexSubs_linkLogin {
        color: ${window.Didomi.getConfig().theme.linkColor};
        text-decoration: none;
        float: left;
      }

      #cpexSubs_bottom {
        background-color: #eee;
        padding: 20px 40px;
        font-size: 10pt;
        color: gray;
        border-radius: 0px 0px 10px 10px;
      }
        #cpexSubs_bottom a {
          text-decoration: none;
        }
        #cpexSubs_bottom p {
          margin: 0px;
          font-size: 10pt;
          color: gray;
          padding: 0px;
          line-height: 120%;
        }
          #cpexSubs_bottom p:first-letter {
            text-transform: uppercase;
          }
        #cpexSubs_links {
          padding-top: 10px;
          padding-bottom: 10px;
        }

    @media only screen and (max-width: 1080px) {
      #cpexSubs_modal main p {
        height: 10.5em;
      }
    }

    @media only screen and (max-width: 900px) {
      #cpexSubs_modal {
        width: calc(100% - 40px);
        margin: 20px auto;
      }
        #cpexSubs_modal main {
          flex-direction: column;
          position: relative;
        }
    }
    @media only screen and (max-width: 400px) {
      #cpexSubs_modal {
        width: calc(100% - 10px);
        margin: 5px;
      }
        #cpexSubs_modal main {
          padding: 20px;
        }
          #cpexSubs_modal main p {
            max-height: 5em;
          }
    }
  `
}
