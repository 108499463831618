import { cpexLog, regexGetValue, regexGetFirstComment, displayMetaData, awaitDOM, isPrebidLoaded } from '../utils.js'

/**
 * This adapter replaces AdServer object instance registered in the main package instance
 */
export default class AdServerSasic {
  constructor (main) {
    this.main = main
    this.adapter = 'sasic'
  }

  /**
   * Mandatory. Initializes an adserver based on settings
   */
  load () {
    cpexLog('Adserver: Sasic adapter loading')
    this.loading = awaitDOM()
    this.loading.then(() => { cpexLog('Adserver: Sasic adapter loaded') })

    // Register custom render with sasic
    window._sasic_queue.push(['customRender', position => {
      const width = regexGetValue(position.code, 'SASF_WIDTH')
      const height = regexGetValue(position.code, 'SASF_HEIGHT')
      return this.render(position.elementId, position.code, width, height)
    }])
    return this.loading
  }

  /**
   * Mandatory. Returns (as a promise) an array of adUnit codes (hbKey), to be used for Headerbidding
   */
  async getAdsList () {
    return window._sasic.getAllPositions().map(pos => pos.options?.hb_key).filter(val => !!val)
  }

  /**
   * Mandatory. Calls the adserver to get the final ads selected and rendered
   */
  async call () {
    await this.loading // Wait for DOM ready
    const adUnits = {}
    if (isPrebidLoaded()) {
      for (const adUnit of await this.getAdsList()) {
        const wBid = window.pbjs.getHighestCpmBids(adUnit)[0]
        if (wBid) {
          adUnits[wBid.adUnitCode] = { hbid: wBid.cpm, hbid_v: this.main.settings.adserver.bidderTable[wBid.bidderCode] || 'unknown' }
        }
      }
    }
    window._sasic_queue.push(['loadAllHb', adUnits])
    cpexLog('AdServer: SAS called with these AdUnits: ', adUnits)
  }

  /**
   * Mandatory. Returns (in promise) DOM element id for the adUnit/hbKey
   */
  async getElementId (hbKey) {
    const adUnits = Object.values(window._sasic.get(['groups'])).map(group => { return Object.values(group.positions) }).flat().filter(value => !!value)
    return adUnits.find(adUnit => adUnit.options.hb_key === hbKey)?.elementId
  }

  /****************************************************************************/
  /* SPECIFIC methods, to this adapter only                                   */
  /****************************************************************************/

  /**
   * Wraps HB reRender to pass hbKey
   */
  async sasicReRender (hbKey) {
    this.main.headerbidding.reRender(await this.getElementId(hbKey), hbKey)
  }

  /**
   * Returns all element ids for all adUnits, for both S2S and HB. Used in editor test code modal
   */
  getElementsList () {
    return window._sasic.getAllPositions().map(pos => pos.elementId)
  }

  /**
   * Determines if the creatives is from header-bidding
   */
  isFromHB (creative) {
    const firstComment = regexGetFirstComment(creative)
    return firstComment.includes('HB')
  }

  /**
   * This method is an interface for sasic library from ECO
   * false tells them to render themselves, true means we render the creative
   */
  render (elementId, creative, width, height) {
    cpexLog(`AdServer: Sashic returned creative for ${elementId}, size: ${width}x${height}`)
    width = parseInt(width)
    height = parseInt(height)
    const fromHB = this.isFromHB(creative)
    const sspId = parseInt(regexGetValue(creative, 'ADVID'))
    if (this.main.debugMode) {
      try {
        cpexLog('AdServer: SAS rendered this ad: ', elementId)
        const fcId = regexGetValue(creative, 'FCID')
        const parsed = creative.match(/hb_key: (?<value>\S+)/m) // https://regex101.com/r/6x1pVZ/1
        const hbKey = parsed?.groups?.value
        setTimeout(() => { this.prepareMetaData(elementId, { width, height, fromHB, sspId, fcId, hbKey }) }, 1000)
      } catch (e) { console.error('Debug tags failed to render', e) }
    }
    // Filter to only our advertisers, based on allowedSSPs from settings
    const external = !Object.values(this.main.settings.adserver.allowedSSPs).includes(sspId)
    if (fromHB || external) { // from HB or not our advertiser network
      // let sasic render HB ads, custom format catching will be called from within the HB creative
      this.main.regularAds[elementId] = { element: document.getElementById(elementId) }
      return false
    } else {
      // catching for s2s creatives is part of the main render function
      return this.main.render(elementId, creative, width, height)
    }
  }

  /**
   * Prepares an object with useful information for debubbing. Merges info from both adserver and prebid.
   */
  prepareMetaData (elementId, metaData) {
    const creativeMetaData = { // adserver data
      adapter: 'sasic',
      id: elementId + ', fcid: ' + metaData.fcId,
      size: metaData.width + 'x' + metaData.height,
      advid: metaData.sspId
    }
    if (this.main.customAds[elementId]) { // custom format
      creativeMetaData.customType = this.main.customAds[elementId].type
    }
    displayMetaData(elementId, creativeMetaData)
  }
}
