import { cpexLog, cpexError, addIframe, fillNativeTemplate, fillOrtbNativeTemplate, b64DecodeUnicode, triggerTracking, hideAdContent } from '../utils.js'

/**
 * There are two sub-versions of this ad format
 * 1. Data based: Template is filled with data from a postMessage, or renders html fallback
 * 2. Prebid (true native): Template is filled from bidResponse
 * This should be temporary, the plan is to deprecate the first version
 */
export default class Native {
  constructor (elementId, settings, width, height, adUnit) {
    this.type = 'native'
    this.elementId = elementId
    this.settings = settings.formats.native
    this.loaded = false
    this.adUnit = adUnit
    this.width = width
    this.height = height
    this.config = this.settings.adUnitConfig ? this.settings.adUnitConfig[adUnit || elementId] : null

    // DEPRECATED
    // this.iframeCSS = 'display: none' // Needed for HB reRender
    // Listen for postMessage of this 'data ad'
    this.processDataAd = this.processDataAd.bind(this) // Needed for listener reference removal
    window.addEventListener('message', this.processDataAd, false)
    cpexLog('Native: Caught Native custom format, in elementId ' + elementId)
  }

  /**
   * Used for Native ads from Prebid. Probably temporary
  */
  render (nativeData, bidderCode) {
    this.element = document.getElementById(this.elementId)
    const template = this.getTemplate()
    /*
    if (bidderCode === 'smart') {
      try {
        nativeData = nativeData.substring(nativeData.indexOf('{')) // Crop string until the first "{" symbol
        nativeData = nativeData.replace(/,\s*]}/g, ']}').replace(/\\r\\n/g, '').replace(/\\"/g, '"') // Unescape
        nativeData = nativeData.replace(/,(\s*[\]}])/g, '$1') // Remove trailing commas
        const parsed = JSON.parse(nativeData)
        this.element.innerHTML += fillSmartNativeTemplate(parsed, template, this.config)
      } catch (e) {
        cpexError('Native: Error parsing Smart Native data')
      }
    } else {
    }
    */
    this.element.innerHTML += fillOrtbNativeTemplate(nativeData, template, this.config) // Not rendering with pbjs.renderAd to not be contained within an iframe, to keep it responsive
  }

  addPrebidEvents (winningBid) {
    const message = { message: 'Prebid Native', adId: winningBid.adId }
    window.postMessage(JSON.stringify(message)) // Way to trigger emitAdRenderSucceeded (for analytics etc) in prebid
    // Register event for click tracking
    const link = this.element.querySelector('a')
    link.addEventListener('click', () => { window.postMessage(JSON.stringify({ action: 'click', ...message })) })
  }

  getTemplate () {
    let template = defaultTemplate
    // Look for custom templates from settings. Uses elementId names joined in a string (separated by commas) as a key
    if (this.settings.templates) {
      let templateFound = false
      for (const elementIds in this.settings.templates) {
        const parsedIds = elementIds.split(',')
        for (const elId of parsedIds) {
          if (elId === this.elementId) {
            templateFound = true
            template = this.settings.templates[elementIds]
            // If template text is key of another template, it's a refence feature (so don't have to duplicate templates)
            if (template in this.settings.templates) {
              template = this.settings.templates[template]
            }
          }
        }
      }
      // If not found, tries to find template called 'generic'. Otherwise it stays at built-in default
      if (templateFound === false && this.settings.templates.generic) {
        template = this.settings.templates.generic
      }
    }
    return template
  }

  /**
   * DEPRECATED. Triggered with every postMessage, should be filtered down to those expected from our data ad format (native)
   */
  processDataAd (event) {
    // this part is not working properly: event.source === this.iframe.contentWindow
    if (this.iframe && event.source === this.iframe.contentWindow && typeof event.data !== 'undefined' && event.data.creativeType === 'native') {
      if (typeof event.data.data !== 'undefined') {
        cpexLog('Native: Correct postMessage received')
        const data = JSON.parse(b64DecodeUnicode(event.data.data)) // Decode UTF8 Base64 and parse it into an object
        if (data.native) {
          const template = this.getTemplate()
          if (event.data.clickTag) { data.native.link.url = event.data.clickTag } // Replace link with dynamic clickTag
          hideAdContent(this.element)
          this.element.innerHTML += fillNativeTemplate(data.native, template, this.config)
          triggerTracking(event.data) // Add tracking pixels
        } else { cpexError('Native: Missing Native data') }
      } else { cpexError('Native: Ad is missing base64 encoded creative data in postMessage') }
      window.removeEventListener('message', this.processDataAd, false)
    }
  }

  /**
   * DEPRECATED. Places the native container into the page
   */
  prepareIframe () {
    this.element = document.getElementById(this.elementId)
    if (this.element) {
      this.iframe = addIframe(this.element, { width: 548, height: 150, id: this.elementId + '-iframe' }, (iframe) => { // onload callback
        this.loaded = true
        cpexLog('Native: Rendered into ' + this.elementId)
        // Respond to prevent failsafe rendering
        const response = { response: 'cpexHTML5', caf: true }
        this.iframe.contentWindow.postMessage(response, '*')
      })
      // this.iframe.style.cssText = this.iframeCSS
      return this.iframe
    } else {
      cpexError(`Native: Target element '${this.elementId}' not found`)
    }
  }

  /**
   * Returns the page to its original state
   */
  reset () {
    const ads = window.cpexPackage.customAds
    if (ads) { delete ads[this.elementId] }
    window.removeEventListener('message', this.processDataAd, false)
  }
}

const defaultTemplate = `<style>
  .cpex-native a { display: flex; text-decoration: none; color: inherit }
  .cpex-native img { width: 200px; object-fit: cover }
  .cpex-native .text { padding-left: 20px }
</style>
<div class='cpex-native'>
  <a href='\${link}' target='_blank' id='cpexNativeLink' rel='sponsored noopener' >
    <img src='\${img}'>
    <div class='text'>
      <h2>\${title}</h2>
      <p>\${desc}</p>
    </div>
  </a>
</div>`
