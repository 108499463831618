import { cpexLog, cpexError, addElement, cpexWarn, loadScript, addAzureExport, deepMerge, addIframe, fillIframeDoc, isObject, awaitDOM } from './utils.js'
import CMP from './cmp/cmp.js'
import AdServerGoogleTag from './adserver/googletag.js'
import AdServerSasTracker from './adserver/sastracker.js'
import AdServerSasic from './adserver/sasic.js'
import AdServerPaticka from './adserver/paticka.js'
import AdServerTest from './adserver/test.js'
import Headerbidding from './headerbidding/headerbidding.js'
import Formats from './formats/formats.js'

/**
 * Entry point of the library, adds and manages all modules
 */
export default class CpexPackage {
  constructor () {
    this.loaded = false
    this.settingsLoaded = false
    this.loading = new Promise((resolve, reject) => {
      this.loadResolve = resolve
      this.loadReject = reject
    })
    this.version = version // eslint-disable-line
    this.localhost = window.location.href.indexOf('localhost:') > -1
    this.playground = window.location.href.indexOf('playground.cpex.cz') > -1
    this.debugMode = !!window.localStorage.getItem('cpexPackage') || this.localhost || this.playground || window.location.href.indexOf('debug') > -1 // Shows logs if url contains string 'debug'
    this.hasAdblock = false
    // Settings
    this.settings = {} // actual currently used settings
    this.loadedSettings = {} // backup of loaded settings
    this.settingsDefault = { // backup of default settings
      publisher: { sellerId: '0' },
      website: {},
      general: { autoRun: true },
      ab: { enabled: false, sasKey: 'cpexAB' },
      adserver: {
        enabled: true,
        loadPrerequisites: false,
        addConsent: true,
        allowedSSPs: { pubmatic: 1, index: 3, magnite: 4, xandr: 5 },
        bidderTable: { stroeerCore: 'stroeer', appnexus: 'xandr_hb', pubmatic: 'pubmatic_hb', rubicon: 'magnite_hb', 'rubicon-mask': 'magnite_hb', adform: 'adform', 'im-adform': 'im-adform', r2b2: 'r2b2', triplelift: 'triplelift_hb', ix: 'index_hb', smart: 'smart', teads: 'teads', rtbhouse: 'rtbhouse' }
      },
      headerbidding: {
        enabled: true,
        currency: 'USD',
        auctionTimeoutMs: 1000,
        cmpLoadTimeoutMs: 1000,
        cmpActionTimeoutMs: 2000,
        adUnits: [],
        passbacks: {},
        prebidPath: 'https://cdn.cpex.cz/hb/prebid/prebid.min.js',
        analytics: ['cpexAnalytics'],
        userIDs: ['id5Id', 'sharedId', 'criteo', 'czechAdId']
      },
      formats: {
        slideup: { defaultHeight: 0 },
        vignette: { closeTextHTML: 'Zavřít&nbsp;reklamu&nbsp;&nbsp;✕' },
        skin: { contentCSS: 'position: relative; margin-top: 200px' },
        interscroller: {},
        native: {},
        responsive: {}
      },
      cmp: {
        enabled: true,
        type: 'didomi',
        payConfig: {},
        pixelList: [
          'https://a.slunecnice.cz/slunecnice/SETSV/TTL=33696000/consent=%CONSENT%/GDPR=1',
          'https://a.denik.cz/vlm/SETSV/TTL=33696000/consent=%CONSENT%/GDPR=1',
          'https://a.1gr.cz/mafra/SETSV/TTL=33696000/consent=%CONSENT%/GDPR=1',
          'https://a.centrum.cz/cent/SETSV/TTL=33696000/consent=%CONSENT%/GDPR=1',
          'https://a.csfd.cz/csfd/SETSV/TTL=33696000/consent=%CONSENT%/GDPR=1'
        ]
      },
      dsa: {
        enabled: true,
        render: true
      }
    }
    deepMerge(this.settings, window.structuredClone(this.settingsDefault)) // copy defaults
    this.ab = {}
    // Ad register
    this.regularAds = {}
    this.customAds = {}
    // Expose utils
    this.utils = { addElement, cpexLog, cpexWarn, cpexError }
  }

  /**
   * Initializes loading and running
   */
  async init () {
    cpexLog('Main: CpexPackage init, version: ' + window.cpexPackage.version)
    await this.load().catch(e => cpexError('Settings load failed', e))
    if (window.cpexPackageConfig?.autoRun !== false && this.settings.general.autoRun) {
      this.run().catch(e => cpexError('Running failed', e))
    } else if (typeof this.settings.general.customRun === 'function') {
      this.settings.general.customRun()
    }
  }

  /**
   * Waits for settings to be loaded, starts loading for modules, they handle the waiting inside
   */
  async load () {
    try {
      // Load settings
      this.settingsLoaded = await this.loadSettings(1).catch(async (e) => {
        // Try again
        await this.loadSettings(2).catch(e2 => {
          cpexError('Settings not loaded, tried two times', e2)
          this.failsafe()
        })
      })
      if (this.settingsLoaded) { cpexLog('Main: Settings have been loaded succesfully: ', this.settings) }
      // Run scripts from queue, beforeLoad and localStorage, to allow page specific overrides
      this.runQueue()
      if (typeof this.settings.general.beforeLoad === 'function') { await this.settings.general.beforeLoad() }
      this.overrideFromLocalStorage()
      // Load settings from AB tests
      if (this.settings.ab.enabled) {
        this.ab.sasKey = this.settings.ab.sasKey // save from settings, to prevent over-write
        if (typeof this.settings.ab.selectGroup === 'function') { this.ab.group = this.settings.ab.selectGroup() }
        if (!this.ab.group) { // default group selection method, get probabilities and randomly select one groupList key
          const rand = Math.random()
          let sum = 0
          for (const [group, { probability }] of Object.entries(this.settings.ab.groupList)) {
            sum += probability // sum up probabilities
            if (sum > 1) { cpexError('Main: Sum of AB testing probabilities is higher than 1') }
            if (rand < sum) { // check if sum surpasses random number, save winning group and settings path
              this.ab.group = group
              this.ab.path = this.settings.ab.groupList[group].websiteSettings
              break
            }
          }
          // Reload settings if different group wins in AB
          if (this.ab.path !== window.cpexPackageConfig.websiteSettingsPath) {
            await loadScript(this.ab.path, 'Website settings').catch(e => { console.error('AB Settings loading failed', e) }) // It happens, don't send to sentry
            this.reloadSettings()
          }
        }
        cpexWarn(`Main: AB test enabled, current group: ${this.ab.group} (cpexPackage.ab.group)`)
      }
      // Add custom format module
      this.formats = new Formats(this)
      // Load CMP
      if (!this.cmp) { this.cmp = new CMP(this) }
      // Load the proper adserver adapter and instantiate
      if (this.settings.adserver.enabled) { // Adserver
        this.setAdServer()
        if (this.adserver) {
          this.adserver.load()
        } else {
          return false // no point in running HB if adserver setup failed
        }
        // Headerbidding
        if (this.settings.headerbidding.enabled) {
          this.headerbidding = new Headerbidding(this)
          this.headerbidding.load().catch(e => cpexError('Headerbidding loading failed', e))
          await this.headerbidding.prebidLoadedPromise
        } else {
          this.headerbidding = undefined
        }
      } else { // Headerbidding without adserver
        if (this.settings.headerbidding.enabled) {
          this.headerbidding = new Headerbidding(this)
          this.headerbidding.load().catch(e => cpexError('Headerbidding loading failed', e))
          await this.headerbidding.prebidLoadedPromise
        }
      }
      window.dispatchEvent(new window.Event('cpexPackageLoaded'))
      if (typeof this.settings.general.onLoad === 'function') { this.settings.general.onLoad() }

      // sync piano id for cpex dmp
      addAzureExport()

      cpexLog('Main: CpexPackage loaded')
      this.loaded = true
      this.loadResolve()
      return true
    } catch (e) {
      cpexError('Loading failed', e)
      this.loadReject(e)
    }
  }

  /**
   * Runs everything
   */
  async run () {
    cpexLog('Main: CpexPackage running')
    if (this.headerbidding && this.settings.headerbidding.enabled && this.settings.adserver.adapter !== 'test') {
      await this.headerbidding.configure().catch(e => cpexError('HB configuration failed', e))
      this.headerbidding.call()
    } else if (this.adserver && this.settings.adserver.enabled) {
      this.adserver.call()
    } else {
      console.error('cpexPackage: Adserver failed to load')
    }
  }

  /**
   * Refresh all ads. Used mainly for single page applications.
   */
  async refresh () {
    // console.clear()
    cpexLog('Main: CpexPackage refreshing ads')
    this.clearAds()
    await this.loading
    this.run().catch(e => cpexError('Refresh failed', e))
  }

  /**
   * Reload all modules, as if the page was refreshed and refresh. Used mainly for single page applications.
   * Modules have checks against loading files for a second time.
   */
  async reload () {
    this.clearAds()
    cpexLog('Main: CpexPackage reloading')
    this.loaded = false
    this.loading = new Promise((resolve, reject) => {
      this.loadResolve = resolve
      this.loadReject = reject
    })
    await this.load().catch(e => cpexError('Settings reload failed', e))
    this.run().catch(e => cpexError('Refresh failed', e))
  }

  reloadSettings () {
    this.settings = {} // Clear
    this.loadedSettings = {}
    deepMerge(this.settings, window.structuredClone(this.settingsDefault))
    deepMerge(this.settings, window.cpexPublisherSettings)
    deepMerge(this.settings, window.cpexWebsiteSettings)
    deepMerge(this.loadedSettings, this.settings)
  }

  /**
   * Clears all elements of currently rendered ads, reset the ads registry.
   * elementIds is an optional array with a subset of ads to clear
   */
  clearAds (elementIds) {
    if (elementIds) {
      // Clear specific ad codes
      elementIds.forEach(code => {
        if (code) {
          // First try removing custom formats
          const cAd = this.customAds[code]
          if (cAd) { cAd.reset() }
          // Clear regular banners
          const rAd = this.regularAds[code]
          if (rAd && rAd.element) { rAd.element.innerHTML = '' }
          cpexLog('Main: ' + code + ' cleared')
        }
      })
    } else {
      // Clear all rendered ads
      // First try removing custom formats
      for (const elementId in this.customAds) {
        const ad = this.customAds[elementId]
        if (ad.reset) { ad.reset() }
      }
      this.customAds = {}
      // Clear regular banners
      for (const elementId in this.regularAds) {
        const ad = this.regularAds[elementId]
        if (ad.element) { ad.element.innerHTML = '' }
      }
      this.regularAds = {}
      cpexLog('Main: Ads cleared')
    }
  }

  /**
   * Helper function to register ads for use in HB, outside of the usual adserver flow
   */
  registerAd (elementId) {
    return this.regularAds[elementId] = { element: document.getElementById(elementId) }
  }

  /**
   * Loads settings files and merges them with defaults
   * Expects config object with `publisherSettingsPath` and `websiteSettingsPath` URLs to be present on window
   */
  async loadSettings (attempt) {
    // debugger
    // Reset
    this.settings = {}
    this.loadedSettings = {}
    deepMerge(this.settings, window.structuredClone(this.settingsDefault)) // Simple copy
    // Configure
    const config = window.cpexPackageConfig
    if (isObject(config)) {
      try {
        if (!config.publisherSettingsPath && !config.websiteSettingsPath) { throw new Error('Main: Config is missing paths') }
        // Load
        await Promise.all([
          loadScript(config.publisherSettingsPath, 'Publisher settings'),
          loadScript(config.websiteSettingsPath, 'Website settings')
        ]).catch(e => console.error('Settings loading failed', e)) // It happens, don't send to sentry
        // Merge
        if (window.cpexPublisherSettings && window.cpexPublisherSettings) {
          if (window.cpexPublisherSettings) {
            deepMerge(this.settings, window.cpexPublisherSettings)
          } else { throw new Error('Production error in publisher settings') }
          if (window.cpexWebsiteSettings) {
            deepMerge(this.settings, window.cpexWebsiteSettings)
          } else { throw new Error('Production error in website settings') }
        } else {
          if (attempt === 2) {
            cpexError('Both setting files not loaded, tried two times')
          } else {
            console.error('Both settings not loaded')
          }
          return false
        }
        deepMerge(this.loadedSettings, this.settings) // Clone to loaded
        this.settingsLoaded = true
        return true
      } catch (e) { throw new Error('Main: Failed to load settings. Error: ', e) }
    } else {
      cpexError('Main: Missing window.cpexPackageConfig object, using default settings')
    }
  }

  /**
   * Looks for settings in local storage, to override the currently loaded
   */
  overrideFromLocalStorage () {
    try {
      const localSettings = JSON.parse(window.localStorage.getItem('cpexPackage'))
      if (isObject(localSettings)) {
        deepMerge(this.settings, localSettings)
        cpexWarn('Main: Settings overriden from LocalStorage (key "cpexPackage")')
      }
    } catch (e) { cpexError('Main: LocalStorage parsing failed. Error: ', e) }
  }

  /**
   * Sets the correct adserver module based on settings
   */
  setAdServer () {
    if (this.adserver) { if (this.adserver.adapter === this.settings.adserver.adapter) { return } }
    switch (this.settings.adserver.adapter) {
      case 'googletag': this.adserver = new AdServerGoogleTag(this); break
      case 'sastracker': this.adserver = new AdServerSasTracker(this); break
      case 'sasic': this.adserver = new AdServerSasic(this); break
      case 'paticka': this.adserver = new AdServerPaticka(this); break
      case 'test': this.adserver = new AdServerTest(this); break
      default: cpexWarn('Main: Missing or unexpected adserver adapter name: ' + this.settings.adserver.adapter)
    }
  }

  /**
   * Looks for an existing que and runs it. This is done after loading of setting to allow for page specific overrides.
   */
  runQueue () {
    if (Array.isArray(window.cpexPackageQueue)) {
      window.cpexPackageQueue.forEach((item) => {
        if (typeof item === 'function') { item() }
      })
      window.cpexPackageQueue = [] // clear queue
    }
  }

  /**
   * Tries to catch our custom formats (from S2S), then returns true and renders them. If it doesn't, returns false.
   */
  render (elementId, creative, width, height) {
    let ad
    let cpexRender
    width = parseInt(width)
    height = parseInt(height)
    if (this.settingsLoaded === false) { cpexError('Trying to render while not fully loaded') }

    // Try catching and rendering custom format based on size
    const customFormat = this.formats.match(elementId, width, height)
    if (customFormat) {
      if (this.settings.formats[customFormat].enabled) {
        if (document.getElementById(elementId)) {
          const iframe = this.formats.prepareIframe(customFormat, elementId, width, height)
          fillIframeDoc(iframe.contentWindow.document, creative)
        } else {
          cpexError(`Target element '${elementId}' for custom format rendering not found`)
        }
        ad = this.customAds[elementId]
        cpexRender = true
      } else {
        cpexLog(`Found ad that could be ${customFormat} (CPEx custom format), but it's not enabled in settings`)
        cpexRender = false
      }
    } else {
      ad = this.regularAds[elementId] = { element: document.getElementById(elementId) }
      cpexRender = false
    }
    window.dispatchEvent(new window.CustomEvent('cpexAdRendered', { detail: ad }))
    return cpexRender
  }

  /**
   * Renders any ad into the page, if the package is loaded. Otherwise it adds it to the queue.
   * If it's our format, we catch it and render differently
   */
  renderAny (elementId, creative, width, height) {
    if (this.loaded) {
      const catchSuccess = this.render(elementId, creative, width, height)
      if (catchSuccess === false) {
        const element = document.getElementById(elementId)
        const domId = elementId + '-iframe'
        // Save ad reference for later use (has to exist before render, for HB ReRender to find it)
        const iframe = addIframe(element, { width: parseInt(width), height: parseInt(height), id: domId })
        this.regularAds[elementId] = { type: 'banner', elementId, element, iframe }
        fillIframeDoc(iframe.contentWindow.document, creative)
      }
      return true
    } else {
      cpexWarn('Trying to render while not fully loaded, adding to queue')
      window.cpexPackageQueue.push(() => { window.cpexPackage.renderAny(elementId, creative, width, height) })
    }
  }

  failsafe () {
    if (window.top.sasTracker) {
      this.settings.adserver.adapter = 'sastracker'
    } else if (window.googletag.pubads === 'function') {
      this.settings.adserver.adapter = 'googletag'
    } else if (window._sasic_queue) {
      this.settings.adserver.adapter = 'sasic'
    } else if (window.Unidata) {
      this.settings.adserver.adapter = 'paticka'
    }
    this.setAdServer()
    this.adserver.load()
    this.adserver.call()
  }
}

// Start the package
if (window.cpexPackage) {
  cpexLog('Main: CpexPackage already present')
} else {
  window.cpexPackage = new CpexPackage()
  window.dispatchEvent(new window.Event('cpexPackageAdded'))
  if (window.cpexPackageConfig?.autoInit !== false) {
    window.cpexPackage.init()
  }
}

cpexLog('Main: CpexPackage script added')
awaitDOM().then(() => { cpexLog('Main: DOM loaded') })
