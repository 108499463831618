import { cpexLog, cpexLogHeadline, cpexError, cpexWarn, displayMetaData, loadScript, clone, clamp, addIframe, isPrebidLoaded, isFilledArray, isObject, hasAdblock, hideAdContent, getId5PartnerData, getIpAddress, getCookie } from '../utils.js'
import DSA from '../dsa.js'

/**
 * This sub-module is optional. It sets up and manages headerbidding library Prebid.js to run a client-side auction,
 * before the package makes a request to an adserver. Read more at https://docs.prebid.org/prebid/prebidjs.html
 */
export default class Headerbidding {
  constructor (main) {
    this.main = main
    this.aliases = {}
    this.adUnits = []
    this.prebidLoaded = false
    this.prebidLoadedPromise = new Promise((resolve, reject) => {
      this.loadedResolve = resolve
      this.loadedReject = reject
    })
    this.ipAddress = {}
  }

  /**
   * Loads Prebid.js library
   */
  async load () {
    // Trigger for load finish
    this.pbjs = window.pbjs = window.pbjs || {}
    window.pbjs.que = window.pbjs.que || []
    window.pbjs.que.push(() => {
      this.prebidLoaded = true
      this.loadedResolve()
      cpexLog('Headerbidding: Loaded')
    })

    if (!this.prebidLoaded) {
      // Check if prebid is really loaded, not just an empty object with a que
      if (isPrebidLoaded()) {
        cpexWarn('Headerbidding: Publisher using own Prebid, version: ', window.pbjs.version)
      } else {
        // Load Prebid.js
        await loadScript(this.main.settings.headerbidding.prebidPath, 'Prebid')
          .catch(e => {
            hasAdblock(this.main.settings.headerbidding.prebidPath)
              .then(hasAdblock => {
                if (hasAdblock) {
                  this.hasAdblock = this.main.hasAdblock = true
                } else {
                  cpexError('Prebid failed to load, probably wrong path', e)
                }
                this.loadedResolve()
              })
              .catch(e => { cpexWarn('Adblock blocked Prebid from loading') })
          })
      }
    }
  }

  /**
   * Configures Prebid
   */
  async configure () {
    if (this.hasAdblock) { return }
    if (!this.pbjs || !this.pbjs.setConfig) { // makes sure prebid is loaded
      this.load().catch(e => cpexError('Loading of Prebid failed', e))
      await this.prebidLoadedPromise
    }

    // Keywords
    const meta = document.querySelector('meta[name="keywords"]')
    this.keywords = [
      ...(meta?.content ? meta.content.split(',') : []),
      ...(window.s_keywords ? window.s_keywords : []), // ECO
      ...(window.AdsObject?.ball?.geneawords ? window.AdsObject.ball.geneawords.split(',') : []) // VLM
    ]
    this.keywords = this.keywords.map(keyword => keyword.trim())

    if (this.pbjs.configured) {
      // Prebid running again, reset AdUnits
      this.pbjs.removeAdUnit()
      cpexLog('Headerbidding: Reset')
    } else {
      // First time running, configure
      try {
        // Wraps TCF API call to a promise, to wait for consent, for 1 second at most
        await new Promise(resolve => {
          window.__tcfapi('addEventListener', 2, (data, success) => {
            if (data && success) { this.cpexConsent = data.vendor.consents[570] }
            resolve()
          }, [570])
          setTimeout(() => { resolve() }, 1000) // Fallback for a case when CMP does not trigger the callback (seznam.cz on slow network)
        })
      } catch (e) { cpexError('TCF API not available', e) }

      // Get IP address
      try {
        if (this.cpexConsent) { this.ipAddress = await getIpAddress() }
      } catch (e) { cpexError('IP API not reached', e) }

      const config = {
        debug: this.main.debugMode,
        bidderTimeout: this.main.settings.headerbidding.auctionTimeoutMs || 1000,
        consentManagement: {
          gdpr: {
            cmpApi: 'iab',
            defaultGdprScope: true,
            rules: [
              { purpose: 'storage', enforcePurpose: false, enforceVendor: false },
              { purpose: 'basicAds', enforcePurpose: false, enforceVendor: false },
              { purpose: 'measurement', enforcePurpose: false, enforceVendor: false }
            ],
            timeout: this.main.settings.headerbidding.cmpLoadTimeoutMs || 2000,
            actionTimeout: this.main.settings.headerbidding.cmpActionTimeoutMs || 0
          }
        },
        currency: {
          adServerCurrency: this.main.settings.headerbidding.currency,
          defaultRates: { USD: { USD: 1, CZK: 23 }, EUR: { USD: 1, CZK: 24 } }
        },
        schain: { config: { nodes: [{ sid: this.main.settings.publisher.sellerId, asi: 'cpex.cz', hp: 1 }], ver: '1.0', complete: 1 }, validation: 'strict' },
        cache: { url: 'https://prebid.adnxs.com/pbc/v1/cache' },
        appnexusAuctionKeywords: { keyword: this.keywords },
        ortb2: {},
        enableTIDs: true
      }

      // UserIDs
      const idSettings = this.main.settings.headerbidding.userIDs
      if (Array.isArray(idSettings) && idSettings.length && this.pbjs.installedModules.includes('userId')) {
        config.userSync = {
          userIds: [],
          filterSettings: {
            iframe: { bidders: ['connectad', 'sspBC'], filter: 'include' }
          }
        }
        const idPresets = {
          id5Id: { name: 'id5Id', storage: { name: 'id5id', type: 'html5', expires: 365, refreshInSeconds: 8 * 3600 }, externalModuleUrl: 'https://cdn.id5-sync.com/api/1.0/id5PrebidModule.js' },
          sharedId: { name: 'sharedId', storage: { name: '_sharedid', type: 'cookie', expires: 365, refreshInSeconds: 8 * 3600 } },
          criteo: { name: 'criteo' },
          czechAdId: { name: 'czechAdId' }
        }
        if (idSettings.includes('id5Id')) {
          idPresets.id5Id.params = { partner: this.main.settings.publisher.code === 'mafra' ? 469 : 250 }
          if (this.cpexConsent) {
            try { idPresets.id5Id.params.pd = await getId5PartnerData(this.ipAddress) } catch (e) { cpexError('ID5 partner data not loaded', e) }
          }
        }
        idSettings.forEach(id => { if (id in idPresets) { config.userSync.userIds.push(idPresets[id]) } })
      }

      // Seller defined audiences
      if (isObject(window.sellerDefinedAudiences)) {
        if (window.sellerDefinedAudiences.site) {
          config.ortb2 = window.sellerDefinedAudiences
          if (Array.isArray(window.sellerDefinedAudiences.site.content?.data[0]?.segment)) {
            this.sda = window.sellerDefinedAudiences.site.content.data[0].segment.map(obj => obj.id).join()
            config.appnexusAuctionKeywords.sda = this.sda
          }
        } else {
          config.ortb2 = { site: { content: { data: [window.sellerDefinedAudiences] } } }
        }
      }

      // First party device info
      config.ortb2.device = { ua: navigator.userAgent }

      // Send AB group as first-party data. Ensure each level exists.
      if (this.main.ab.group) {
        config.ortb2.user = config.ortb2.user ?? {}
        config.ortb2.user.ext = config.ortb2.user.ext ?? {}
        config.ortb2.user.ext.data = config.ortb2.user.ext.data ?? {}
        config.ortb2.user.ext.data.cpexAB = this.main.ab.group
        config.appnexusAuctionKeywords.cpexAB = this.main.ab.group
      }

      // Send DSA
      if (this.main.settings.dsa.enabled) {
        config.ortb2.regs = config.ortb2.regs || {}
        config.ortb2.regs.ext = config.ortb2.regs.ext || {}
        config.ortb2.regs.ext.dsa = this.main.settings.dsa.customConfig || {
          dsarequired: 1, // supported, but not required
          pubrender: 1, // publisher could render
          datatopub: 1, // optional to send transparency data
          transparency: [{ domain: location.hostname.split('.').reverse().splice(0, 2).reverse().join('.'), dsaparams: [1, 2] }]
        }
      }

      this.pbjs.setConfig(config)

      // Bidder specific schain. 2DO: Make configurable from settings
      if (this.main.settings.publisher.code === 'mafra') { // Mafra specific
        this.pbjs.setBidderConfig({ bidders: ['adform'], config: { schain: { validation: 'relaxed', config: { ver: '1.0', complete: 1, nodes: [{ asi: 'adform.com', sid: '2723', hp: 1 }] } } } })
        this.pbjs.setBidderConfig({ bidders: ['omg-adform'], config: { schain: { validation: 'relaxed', config: { ver: '1.0', complete: 1, nodes: [{ asi: 'adform.com', sid: '2700', hp: 1 }] } } } })
        this.pbjs.setBidderConfig({ bidders: ['fragile-adform'], config: { schain: { validation: 'relaxed', config: { ver: '1.0', complete: 1, nodes: [{ asi: 'adform.com', sid: '2950', hp: 1 }] } } } })
        if (this.ipAddress.ip4) { this.pbjs.setBidderConfig({ bidders: ['adform', 'omg-adform', 'fragile-adform'], config: { ortb2: { device: { ip: this.ipAddress.ip4 } } } }) }
        if (this.ipAddress.ip6) { this.pbjs.setBidderConfig({ bidders: ['adform', 'omg-adform', 'fragile-adform'], config: { ortb2: { device: { ipv6: this.ipAddress.ip6 } } } }) }
      }
      if (this.main.settings.publisher.code === 'csfd') { // CSFD specific
        this.pbjs.setBidderConfig({ bidders: ['projectagora'], config: { schain: { validation: 'strict', config: { ver: '1.0', complete: 1, nodes: [{ asi: 'projectagora.com', sid: this.main.settings.website.name === 'csfd.sk' ? '110439' : '110438', hp: 1 }] } } } })
      }
      this.pbjs.setBidderConfig({ bidders: ['performax'], config: { schain: { config: { ver: '1.0', complete: 1, nodes: [{ asi: 'performax.cz', sid: '2459', hp: 1 }] } } } })

      // Analytics
      if (this.main.settings.headerbidding.analytics.includes('id5Analytics') && this.pbjs.installedModules.includes('id5AnalyticsAdapter')) {
        this.pbjs.enableAnalytics({ provider: 'id5Analytics', options: { partnerId: 250, eventsToTrack: ['auctionEnd', 'bidWon'] } })
      }
      if (this.main.settings.headerbidding.analytics.includes('cpexAnalytics') && this.pbjs.installedModules.includes('cpexAnalyticsAdapter')) {
        this.pbjs.enableAnalytics({ provider: 'cpexAnalytics' })
      }
      if (this.main.settings.headerbidding.analytics.includes('pubmatic') && this.pbjs.installedModules.includes('pubmaticAnalyticsAdapter')) {
        this.pbjs.enableAnalytics({ provider: 'pubmatic', options: { publisherId: 158732 } })
      }
      this.pbjs.configured = true
    }

    this.pbjs.onEvent('beforeRequestBids', (adUnits) => {
      // Running later, to give time to currency module to request rates
      this.pbjs.bidderSettings = {
        standard: {
          adserverTargeting: [
            { key: 'hb_pb', val: (bidResponse) => this.setBidTier(bidResponse) },
            { key: 'hb_adid', val: (bidResponse) => { return bidResponse.adId } }
          ]
        },
        criteo: { storageAllowed: true, fastBidVersion: 'latest' },
        projectagora: { storageAllowed: true }
      }

      // Add (or overwrite) custom bidder settings
      if (this.main.settings.headerbidding.bidderSettings) {
        this.pbjs.bidderSettings = { ...this.pbjs.bidderSettings, ...this.main.settings.headerbidding.bidderSettings }
      }
      // Running later for UserIds to be available
      const userIds = this.pbjs.getUserIds()

      adUnits.forEach(adUnit => {
        adUnit.bids.forEach(bid => {
          if (bid.bidder === 'rubicon' || bid.bidderModuleName === 'rubicon') { this.fillRubiconInventory(bid, userIds) }
          if (bid.bidder === 'appnexus') { // xandr
            bid.params.keywords = [this.keywords.join(',')]
          }
        })
        // add ids to xandr keywords
        const appnexusAuctionKeywords = this.pbjs.getConfig('appnexusAuctionKeywords')
        appnexusAuctionKeywords.id5 = userIds.id5Id ? 1 : 0
        appnexusAuctionKeywords.cxid = userIds.czechAdId ? 1 : 0
        this.pbjs.setConfig({ appnexusAuctionKeywords })
      })
    })

    await this.setAdUnits().catch(e => cpexError('Setting of ad units failed', e))
    cpexLog('Headerbidding: Configured')
  }

  /**
   * Method using logarithmic function to set price buckets. They have to be the same as the server uses.
   * Here is an example of the values: https://docs.google.com/spreadsheets/d/1lnAnKost7KoHCGGRVlaDdjU0JnsqiWc0rHdMXsK1hoI
   */
  calculateBucket (cpmCZK) {
    const minCZK = 7 // lowest price admissable
    const base = 1.39 // logarithmic base
    const val = cpmCZK - minCZK
    if (val <= 0) { return 1 }
    const tier = Math.round(Math.log(val) / Math.log(base))
    return clamp(tier, 1, 16)
  }

  /**
   * Simple finding of bucket based on numbers in an array (tier is index +1)
   */
  getBucket (cpmCZK) {
    const buckets = this.main.settings.headerbidding.customBuckets
    const index = buckets.findIndex(ceil => cpmCZK < ceil) // overflow returns -1
    return index === -1 ? buckets.length + 1 : index + 1 // tiers 1 to (number of indexes + 1)
  }

  /**
   * Determines price tier granularity. Buckets are either calculated or taken from a fixed array of numbers in settings.
   */
  setBidTier (bidResponse) {
    const cpmCZK = this.main.settings.headerbidding.currency === 'CZK' // has to be calculated in CZK
      ? bidResponse.cpm
      : this.pbjs.convertCurrency(bidResponse.cpm, this.main.settings.headerbidding.currency, 'CZK')
    if (typeof cpmCZK === 'number') {
      return Array.isArray(this.main.settings.headerbidding.customBuckets)
        ? this.getBucket(cpmCZK)
        : this.calculateBucket(cpmCZK)
    } else {
      cpexError('cpmCZK is not a number')
    }
  }

  /**
   * Starts Prebid.js auction, calls SSPs
   */
  call () {
    /*
    // consent analytics
    if (window.Sentry) {
      try {
        // sentry: team tier
        window.Sentry.captureEvent({
          message: 'Headerbidding analytics',
          tags: { hb_cpex_consent: this.cpexConsent },
          level: 'info'
        })
        // sentry: business tier (custom performance metric)
        if (window.Sentry.setMeasurement) { window.Sentry.setMeasurement('hb_cpex_consent', +this.cpexConsent) }
      } catch (e) { cpexError('Sentry failed', e) }
    }
    */

    cpexLog('Headerbidding: Called')
    if (this.hasAdblock) { return this.callAdserverIfEnabled() } // skip bidding, prebid not loaded
    const failsafe = new Promise(resolve => setTimeout(resolve, this.main.settings.headerbidding.cmpLoadTimeoutMs + this.main.settings.headerbidding.cmpActionTimeoutMs + this.main.settings.headerbidding.auctionTimeoutMs))
    if (isFilledArray(this.adUnits)) {
      const auction = this.pbjs.requestBids()
      // failsafe timeout (sum of all timeouts), in case of prebid`s failure to resolve auction
      Promise.race([auction, failsafe]).then(result => { // { bids, timedOut, auctionId }
        if (result && result.bids) {
          this.bidsBack(true, result.bids)
        } else {
          cpexError('Headerbidding: Prebid failed to return bids')
          this.callAdserverIfEnabled()
        }
      })
    } else {
      cpexWarn('Headerbidding: No adUnits, skipping auction')
      this.callAdserverIfEnabled()
    }
  }

  callAdserverIfEnabled () {
    if (this.main.adserver && this.main.settings.adserver.enabled === true) {
      this.main.adserver.call()
    }
  }

  /**
   * Run only HB auction, with option to specify a subset of configured adUnits.
   * Returns a promise that resolves once the auction is finished
   * adUnitCodes: Array of names - optional
   */
  async refresh (adUnitCodes) {
    cpexLog('Headerbidding: Called')
    const auction = await this.pbjs.requestBids({ adUnitCodes })
    if (auction.bids) {
      const elementIds = await Promise.all(adUnitCodes.map(async (adUnit) => await this.main.adserver.getElementId(adUnit)))
      this.main.clearAds(elementIds)
      this.bidsBack(false, auction.bids)
    } else {
      cpexWarn('Headerbidding: No valid adUnits, auction skipped')
    }
    return true
  }

  /**
   * Filters adUnits before auction, based on custom filters (originating in adunit sheets)
   */
  filterAdUnitsBeforeAuction (adUnits) {
    const isAboveMax = (filter) => { return filter.maxWidth ? window.innerWidth > parseInt(filter.maxWidth) : false }
    const isBelowMin = (filter) => { return filter.minWidth ? window.innerWidth < parseInt(filter.minWidth) : false }

    const urlIs = (filter) => { return filter.urlIs ? filter.urlIs.split(',').find(str => { return str === window.location.host + window.location.pathname }) !== undefined : false } // if any of values equals url - uses FIND converted to boolean
    const urlIsNot = (filter) => { return filter.urlIsNot ? filter.urlIsNot.split(',').find(str => { return str === window.location.host + window.location.pathname }) === undefined : false } // if none of values equals url - uses FIND converted to boolean
    const urlContains = (filter) => { return filter.urlHas ? filter.urlHas.split(',').find(str => { return window.location.href.indexOf(str) !== -1 }) !== undefined : false } // if none of values are within url - uses FIND converted to boolean
    const urlContainsNot = (filter) => { return filter.urlHasNot ? filter.urlHasNot.split(',').every(str => { return window.location.href.indexOf(str) === -1 }) : false } // if all values are not within url - uses EVERY

    const noVariable = (filter) => { return window.cpexAdUnitParam && filter.variable ? window.cpexAdUnitParam === filter.variable : false }
    const hasCookie = (filter) => { return getCookie(filter.cookie) }
    const removingAdUnit = (adUnit, reason) => { cpexLog('Headerbidding: Filtered out ' + adUnit.code + ', reason: ' + reason, adUnit) }

    return adUnits.filter(adUnit => {
      if (isObject(adUnit.filter)) {
        if (isAboveMax(adUnit.filter)) { removingAdUnit(adUnit, 'Is above specified maximum width'); return false }
        if (isBelowMin(adUnit.filter)) { removingAdUnit(adUnit, 'Is below specified minimum width'); return false }
        if (urlIs(adUnit.filter)) { removingAdUnit(adUnit, 'URL is one of the location strings'); return false }
        if (urlIsNot(adUnit.filter)) { removingAdUnit(adUnit, 'URL is not one of the location strings'); return false }
        if (urlContains(adUnit.filter)) { removingAdUnit(adUnit, 'URL contains at least one of the strings'); return false }
        if (urlContainsNot(adUnit.filter)) { removingAdUnit(adUnit, 'URL does not contain any of the strings'); return false }
        if (noVariable(adUnit.filter)) { removingAdUnit(adUnit, 'Page does not contain required variable'); return false }
        if (hasCookie(adUnit.filter)) { removingAdUnit(adUnit, 'Page contains filtering cookie'); return false }
      }
      return true
    })
  }

  /**
   * Filters adUnits after auction, based on custom filters (originating in adunit sheets)
   */
  filterAdUnitsAfterAuction (bids) {
    this.adUnits.forEach(adUnit => {
      if (isObject(adUnit.filter)) {
        if (adUnit.filter.skin) {
          const skinFound = bids.find(bid => this.main.formats.isSkin(bid.width, bid.height))
          if (skinFound) {
            this.pbjs.removeAdUnit(adUnit.code)
            cpexLog('Headerbidding: Filtered out an adUnit, reason: Skin present', adUnit)
          }
        }
      }
    })
  }

  /**
   * Sets adUnits that are in the current page, also look for aliases
   */
  async setAdUnits () {
    // performance analytics
    // this.isDomReady = document.readyState === 'interactive' || document.readyState === 'complete'
    // this.adUnitsReady = Date.now() - performance.timeOrigin

    this.adUnits = []
    // Check data from settings
    if (!this.main.settings.headerbidding.adUnits) {
      return cpexError('Headerbidding: Failed, no AdUnits found')
    }
    if (!Array.isArray(this.main.settings.headerbidding.adUnits)) {
      return cpexError('Headerbidding: Failed, AdUnits are not an array')
    }

    // Filter based on filters in hb settings
    const filteredAdUnits = this.filterAdUnitsBeforeAuction(clone(this.main.settings.headerbidding.adUnits))
    let expectedAdUnits = []
    if (this.main.adserver) {
      expectedAdUnits = await this.main.adserver.getAdsList().catch(e => cpexError('Ads list not returned', e))
    }

    // Add subset of positions found in the page
    if (filteredAdUnits.length > 0) {
      filteredAdUnits.forEach(adUnit => {
        // Check if the adUnit is in the current page. See if the adUnit element matches ID in the page, or in expectedAdUnits from getAdsList()
        if (document.getElementById(adUnit.code) || expectedAdUnits.includes(adUnit.code)) {
          // Add native assets
          if (Object.keys(adUnit.mediaTypes).includes('native')) {
            adUnit.mediaTypes.native = {
              ortb: {
                ver: '1.2',
                context: 1,
                plcmttype: 1,
                assets: [
                  { id: 1, required: 1, img: { type: 3, wmin: 199, hmin: 199 } }, // image
                  { id: 2, required: 1, title: { len: 90 } }, // title
                  { id: 3, required: 0, data: { type: 2 } }, // description
                  { id: 4, required: 0, data: { type: 1 } } // sponsored
                ],
                eventtrackers: [{ event: 1, methods: [1] }],
                privacy: 1
              }
            }
          }
          // Add video config
          if (Object.keys(adUnit.mediaTypes).includes('video')) {
            // startdelay = preroll, see https://docs.prebid.org/dev-docs/bidders/rubicon.html#mediatypesvideo
            const videoType = adUnit.mediaTypes.video.type === 'outstream' ? 'outstream' : 'instream'
            adUnit.mediaTypes.video = { plcmt: 1, placement: 1, startdelay: 0, context: videoType, mimes: ['video/mp4', 'video/x-flv'], protocols: [1, 2, 3, 4, 5, 6, 7, 8], api: [1, 2, 3, 4, 5], linearity: 1, ...adUnit.mediaTypes.video }
            // adUnit.video = { divId: 'preroll-1-player' } // CHECK IF CORRECT, SHOULD BE ALREADY SET

            this.pbjs.setConfig({
              video: {
                providers: [{
                  divId: adUnit.video.divId,
                  vendorCode: 2, // video.js vendorCode
                  playerConfig: {
                    params: {
                      adPluginConfig: { numRedirects: 10, debug: true }, // ima config
                      vendorConfig: { controls: true, autoplay: false, preload: 'auto' } // player config
                    }
                  }
                }]
              }
            })
            this.pbjs.onEvent('videoSetupFailed', e => { cpexWarn('Player setup failed: ', e) })
            this.pbjs.onEvent('videoBidError', e => { cpexWarn('An Ad Error came from a Bid: ', e) })
          }
          this.adUnits.push(adUnit)
        }
      })
    } else { this.adUnits = filteredAdUnits }

    // Fix for r2b2 and set aliases
    for (const adUnit of this.adUnits) {
      for (const bid of adUnit.bids) {
        if (bid.bidder === 'r2b2' && this.main.adserver.getElementId) { bid.params.element = '#' + await this.main.adserver.getElementId(adUnit.code) }
        if (bid.bidderModuleName && !this.aliases[bid.bidder]) {
          this.aliases[bid.bidder] = bid.bidderModuleName
          const options = {}
          if (bid.bidder.includes('adform')) { options.gvlid = 50 } // Adform A/S
          if (bid.bidder.includes('rubicon')) { options.gvlid = 52 } // Magnite, Inc.
          if (bid.bidder.includes('pubmatic')) { options.gvlid = 76 } // PubMatic, Inc
          this.pbjs.aliasBidder(bid.bidderModuleName, bid.bidder, options)
        }
      }
    }

    cpexLog('AdUnits for Prebid added', this.adUnits)
    this.pbjs.addAdUnits(this.adUnits)
    this.pbjs.CPEX_adUnits = this.adUnits // Legacy, added for cooperation with r2b2
  }

  /**
   * For Rubicon (Magnite) bidder we can pass additional information for targetting and analytics purposes
   */
  fillRubiconInventory (bid, userIds) {
    bid.params.inventory = { domain: [window.location.hostname], gdpr: this.cpexConsent ? [1] : [0] }
    // Add keywords
    if (this.keywords.length > 0) { bid.params.inventory.keyword = [this.keywords.join(',')] }
    // Add SDA segments
    if (this.sda) { bid.params.inventory.iab_cont = this.sda }
    // Add userIds
    if (Object.keys(userIds).length !== 0) {
      bid.params.inventory = {
        ...bid.params.inventory,
        cxidc: [userIds.czechAdId ? 1 : 0],
        crid: [userIds.criteoId ? 1 : 0],
        shid: [userIds.sharedid ? 1 : 0],
        pcid: [userIds.pubcid ? 1 : 0],
        id5: [typeof userIds.id5id !== 'undefined' && userIds.id5id.uid !== '0' && window.localStorage.getItem('id5id') ? 1 : 0]
      }
    }
    if (this.main.ab.group) { bid.params.inventory.cpexAB = this.main.ab.group }
  }

  /**
   * Called when auction ends, handles rendering of winning bids, passbacks and calling of adserver
   */
  bidsBack (callAdserver, bids, timedOut, auctionId) {
    // saving winning bids
    const winningBids = this.pbjs.getHighestCpmBids()
    this.filterAdUnitsAfterAuction(winningBids)
    this.saveBids(bids)

    // dispatch video event for each video winning bid
    this.adUnits.forEach(adUnitObject => {
      const winningBid = winningBids.find(bid => bid.adUnitCode === adUnitObject.code)
      if (winningBid?.mediaType === 'video') { // has video winning bid
        window.dispatchEvent(new window.CustomEvent('cpexAuctionDoneVideo', { detail: winningBid }))
      }
    })

    // if adserver is disabled, render directly all winning bids. otherwise call adserver
    if (this.main.settings.adserver.enabled === false) {
      this.adUnits.forEach(adUnitObject => { // iterate all adUnits
        const elementId = adUnitObject.code
        const element = document.getElementById(elementId)
        if (element) {
          this.main.regularAds[elementId] = { element }
          const winningBid = this.winningBids[elementId]
          const passback = this.main.settings.headerbidding.passbacks[elementId] // look for passback function in settings
          if (winningBid) { // has winning bid
            this.reRender(elementId)
          } else if (typeof passback === 'function') { // has passback
            passback()
          }
          // if (this.main.debugMode) { setTimeout(() => { this.prepareMetaData(elementId) }, 50) } // wait a bit to render debug
        } else { cpexWarn('Headerbidding: Element for rendering not found') }
      })
    } else if (callAdserver) {
      this.main.adserver.call()
    }
    cpexLog('Headerbidding: BidsBack')
  }

  /**
   * Save winning bids for later use (rendering)
   */
  saveBids (bids = {}) {
    // winning bids
    this.winningBids = {}
    this.winningBidsVideo = {}
    this.adUnits.forEach(adUnitObject => {
      const highestBid = this.pbjs.getHighestCpmBids(adUnitObject.code)[0]
      if (highestBid) {
        this.winningBids[adUnitObject.code] = highestBid
        if (highestBid.mediaType === 'video') { this.winningBidsVideo[adUnitObject.code] = highestBid }
      }
    })
    this.pbjs.winningBids = this.winningBids // Customary and to make it more easily reachable

    // console logging
    if (this.main.debugMode) {
      // bids from current auction
      const currentBids = []
      const bidRequestIds = []
      Object.values(bids).forEach(adUnit => {
        let highestBid = { cpm: 0 }
        adUnit.bids.forEach(bid => { if (bid.cpm > highestBid.cpm) { highestBid = bid } })
        if (highestBid.adUnitCode) {
          currentBids.push({ adUnit: highestBid.adUnitCode, bidderCode: highestBid.bidderCode, width: highestBid.width, height: highestBid.height, domain: highestBid.meta?.advertiserDomains?.[0], cpm: highestBid.cpm, originalCpm: highestBid.originalCpm, adId: highestBid.adId, currency: highestBid.currency, ttl: highestBid.ttl })
          bidRequestIds.push(highestBid.requestId)
        }
      })
      cpexLogHeadline(`Headerbidding: Current auction winning bids (${Object.keys(currentBids).length}):`)
      console.table(currentBids)

      const cachedBids = []
      Object.values(this.winningBids).forEach(row => { // set columns for table log
        // check if row.requestId isn't in bidRequestIds
        if (!bidRequestIds.includes(row.requestId)) {
          cachedBids.push({ adUnit: row.adUnitCode, bidderCode: row.bidderCode, width: row.width, height: row.height, domain: row.meta?.advertiserDomains?.[0], cpm: row.cpm, originalCpm: row.originalCpm, adId: row.adId, currency: row.currency, ttl: row.ttl })
        }
      })
      cpexLogHeadline(`Headerbidding: Cached winning bids (${Object.keys(cachedBids).length}):`)
      console.table(cachedBids)
    }
  }

  /**
   * When adserver library renders the winning creative into the page, this method is fired from HB creative itself.
   * Tries to catch and render custom formats. If it doesn't, it adds another iframe for HB creative and lets prebid render the actual ad there.
   */
  reRender (elementId, hbKey) {
    const adUnit = hbKey || elementId
    elementId = elementId || hbKey
    const el = document.getElementById(elementId)
    if (el) {
      const ad = this.main.regularAds[elementId] || this.main.registerAd(elementId) // In case it's HB only, register the regular ad
      const winningBid = this.winningBids[adUnit]
      if (winningBid?.adId) {
        if (this.main.settings.dsa.enabled && this.main.settings.dsa.render) {
          const dsaData = winningBid.ext?.dsa || winningBid.meta?.dsa
          if (dsaData) { ad.dsa = new DSA(dsaData, ad.element) }
        }
        const customFormat = this.main.formats.match(elementId, winningBid.width, winningBid.height, winningBid.bidderCode)
        // 2DO?: Missing check for enabled format - if (this.main.settings.formats[customFormat].enabled), probably not needed
        if (winningBid.native) { // Temporary catching of ortb native, until they can be rendered with pbjs.renderAd: https://github.com/prebid/prebid-universal-creative/issues/200
          const native = this.main.formats.create('native', elementId, winningBid.width, winningBid.height)
          this.main.customAds[elementId] = native
          native.render(winningBid.native, winningBid.bidderCode)
          native.addPrebidEvents(winningBid)
          cpexLog('Headerbidding: True Native Ad reRendered from HB: ', winningBid.adUnitCode)
        } else if (this.main.settings.publisher.code === 'mafra' && customFormat === 'slideup') {
          // Publisher rendered format
          if (this.main.adserver.renderMafraSlideup) {
            this.main.adserver.renderMafraSlideup(winningBid)
          } else { // GAM version
            hideAdContent(elementId)
            const iframe = this.prepareIframe(elementId, ad, winningBid.width, winningBid.height) // Get regular iframe
            this.pbjs.renderAd(iframe.contentWindow.document, winningBid.adId)
          }
        } else {
          hideAdContent(elementId)
          // Prepare iframe container
          const iframe = customFormat
            ? this.main.formats.prepareIframe(customFormat, elementId, winningBid.width, winningBid.height, adUnit) // Make custom ad container
            : this.prepareIframe(elementId, ad, winningBid.width, winningBid.height) // Get regular iframe
          this.pbjs.renderAd(iframe.contentWindow.document, winningBid.adId)
        }
        cpexLog('Headerbidding: Ad reRendered from Prebid adUnit: ', adUnit)
        window.dispatchEvent(new window.CustomEvent('cpexAdReRendered', { detail: ad }))
      } else {
        cpexWarn('Headerbidding: Winning bid not found, or missing adId')
      }
      if (this.main.debugMode) { setTimeout(() => { this.prepareMetaData(winningBid, elementId) }, 50) }
    } else {
      cpexError('Headerbidding: Element for ' + adUnit + ' not found, cannot update it with HB creative')
    }
  }

  /**
   * Add iframe with optional custom css
   */
  prepareIframe (elementId, ad, width, height, attributes) {
    ad.iframe = addIframe(ad.element, { id: elementId + '_iframe_hb', width, height, ...attributes })
    if (ad.iframeCSS) { ad.iframe.style.cssText = ad.iframeCSS }
    return ad.iframe
  }

  /**
   * Prepares an object with useful information for debubbing. Merges info from both adserver and prebid.
   */
  prepareMetaData (winningBid, elementId, metaData = {}) {
    if (winningBid) {
      metaData.hb = { bidder: winningBid.bidder, cpm: winningBid.cpm, size: (winningBid.width || '0') + '×' + (winningBid.height || 'O') }
    }
    if (this.main.customAds[elementId]) { // custom format
      metaData.customType = this.main.customAds[elementId].type
    }
    displayMetaData(elementId, metaData)
  }
}
