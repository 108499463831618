import { cpexLog, displayMetaData, awaitDOM, isPrebidLoaded, cpexError } from '../utils.js'

/**
 * This adapter replaces AdServer object instance registered in the main package instance
 */
export default class AdServerPaticka {
  constructor (main) {
    this.main = main
    this.adapter = 'paticka'
    this.loading = true
  }

  /**
   * Mandatory. Initializes an adserver based on settings
   */
  load () {
    cpexLog('Adserver: Paticka adapter loading')
    this.loading = awaitDOM()
    this.loading.then(() => { cpexLog('Adserver: Paticka adapter loaded') })

    // Register custom render?
    return this.loading
  }

  /**
   * Mandatory. Returns (as a promise) an array of elementIds, to be used as headerbidding adUnits
   */
  async getAdsList () {
    return typeof window.Unidata.biddingData[0] === 'object' ? Object.keys(window.Unidata.biddingData[0]) : []
  }

  /**
   * Mandatory. Calls the adserver to get the final ads selected and rendered
   * adUnits - optional array of adUnit names
   */
  async call (adUnits) {
    await this.loading // Wait for DOM ready
    if (window.Unidata?.biddingData) {
      window.sasBidding = window.sasBidding || {}
      // Add bids
      if (this.main.headerbidding && isPrebidLoaded()) {
        for (const elementId of await this.getAdsList()) {
          if (adUnits && !adUnits.includes(elementId)) { continue } // Skip for selective refresh - for other adUnits
          const wBid = window.pbjs.getHighestCpmBids(elementId)[0]
          if (wBid) {
            const bidData = {
              hbid: wBid.cpm,
              hbid_v: this.main.settings.adserver.bidderTable[wBid.bidderCode] || 'unknown'
            }
            if (wBid.vastUrl) { bidData.vast_url = encodeURIComponent(wBid.vastUrl) }
            window.sasBidding[elementId] = bidData
          }
        }
      }
    } else {
      cpexError('Missing Unidata.biddingData')
    }
    if (typeof window.sasBiddingDone === 'function') {
      window.sasBiddingDone()
    } else {
      cpexError('sasBiddingDone function not present')
    }
    cpexLog('AdServer: SAS called with these AdUnits: ', window.sasBidding)
  }

  /**
   * Mandatory. Returns (in promise) DOM element id for the adUnit/hbKey
   */
  async getElementId (hbKey) {
    const adsList = await this.getAdsList()
    return adsList.includes(hbKey) ? hbKey : null
  }

  /****************************************************************************/
  /* SPECIFIC methods, to this adapter only                                   */
  /****************************************************************************/

  /**
   * Refresh specific to paticka.js, able to refresh only certain ad positions.
   * adUnits - optional array of adUnit codes
   */
  async refresh (adUnits) {
    window.sasBidding = {}
    // Enrich the AdsObject with bids from headerbidding
    if (this.main.headerbidding) {
      await this.main.headerbidding.refresh(adUnits)
    }
    // Adserver call with optional subset of adUnits
    this.call(adUnits)
    cpexLog('AdServer: Adserver refreshed for these adUnits: ', adUnits)
    return true
  }

  /**
   * Wraps HB reRender to add metadata
   */
  patickaReRender (elementId) {
    this.main.regularAds[elementId] = { element: document.getElementById(elementId) }
    this.main.headerbidding.reRender(elementId)
    if (this.main.debugMode) {
      setTimeout(() => { this.prepareMetaData(elementId) }, 250)
    }
  }

  renderMafraSlideup (winningBid) {
    if (window.Ads) {
      const viewportWidth = document.documentElement.clientWidth || window.innerWidth
      let adWidth = winningBid.width
      let adHeight = winningBid.height
      let scale = 1

      // Scale down if needed
      if (winningBid.width > viewportWidth) {
        scale = viewportWidth / winningBid.width
        adWidth = winningBid.width * scale
        adHeight = winningBid.height * scale
      }

      const onShow = () => {
        const iframeEl = document.getElementById(`${winningBid.adUnitCode}_iframe_hb`)
        if (iframeEl) {
          this.main.headerbidding.pbjs.renderAd(iframeEl.contentWindow.document, winningBid.adId)
          const styleEl = iframeEl.contentDocument.createElement('style')
          styleEl.textContent = `body { transform: scale(${scale}); transform-origin: top left }`
          iframeEl.contentDocument.head.appendChild(styleEl)
        }
      }

      const iframeStyle = `max-width:${adWidth}px; max-height:${adHeight}px; `
      const html = `<iframe id='${winningBid.adUnitCode}_iframe_hb' width='${adWidth}' height='${adHeight}' style='${iframeStyle}' align='middle' scrolling='no' frameborder='0' marginwidth='0' marginheight='0' allowtransparency='true'></iframe>`
      window.Ads.mobilniSticky({ onShow, html, async: winningBid.adUnitCode, noCookie: true, transparent: true, instant: true })
    } else {
      cpexError('Missing Ads library')
    }
  }

  /**
   * Prepares an object with useful information for debubbing. Merges info from both adserver and prebid.
   */
  prepareMetaData (elementId) {
    const creativeMetaData = { adapter: 'paticka', id: elementId } // adserver data, add more when s2s rendering is added
    if (this.main.customAds[elementId]) { // custom format
      creativeMetaData.customType = this.main.customAds[elementId].type
    }
    displayMetaData(elementId, creativeMetaData)
  }
}
