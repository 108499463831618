import { cpexLog, cpexWarn, cpexError, addTCFStub, loadScript, addPixel } from '../utils.js'
import { PayOkay } from './pay.js'

/**
 * Consent Management Platform (banner and TCF API), based on iab specs
 */
export default class CMP {
  constructor (main) {
    this.main = main
    this.loaded = false
    this.settings = this.main.settings.cmp
    if (this.settings.enabled) {
      if (this.settings.payEnabled) { window.didomiConfig = { notice: { enable: false } } } // disable opening of notice for pay variant
      this.addDidomi()
      if (this.settings.payEnabled) {
        this.addPayOkay()
      }
    } else if (!window.__tcfapi) {
      addTCFStub() // Fallback for missing TCF stub
    }
  }

  /**
   * Load Didomi SDK, documentation at developers.didomi.io
   */
  addDidomi () {
    if (!window.Didomi) {
      if (window.__tcfapi) {
        cpexWarn('CMP: Generic CMP already present')
        this.loaded = true
      } else {
        addTCFStub()
        const hostname = this.main.localhost ? 'cpex.cz' : document.location.hostname
        const target = this.settings.didomiTemplate ? '?target_type=notice&target=' + this.settings.didomiTemplate : '?target=' + hostname
        const src = 'https://sdk.privacy-center.org/9a8e2159-3781-4da1-9590-fbf86806f86e/loader.js' + target
        loadScript(src, 'Didomi loader for SDK')
        window.didomiEventListeners = window.didomiEventListeners || []
        window.didomiOnReady = window.didomiOnReady || []
        window.didomiOnReady.push(() => {
          this.loaded = true
          cpexLog('CMP: Didomi loaded')
          // Protect against Didomi misconfiguration
          if (window.Didomi.isTCFEnabled() === false) { cpexError('TCF compliance disabled in Didomi console') }
          // Add additional functionality
          this.fireConsentPixels()
          this.addCloseButton()
        })
      }
    } else {
      cpexWarn('CMP: Didomi already present')
      this.loaded = true
    }
  }

  /**
   * Load Pay or Okay variant - our custom modal, an extension built on top of Didomi SDK
   */
  addPayOkay () {
    window.didomiEventListeners.push({ event: 'preferences.clicksavechoices', listener: () => { window.cpexCmpSubscription.hideModal() } })
    window.didomiEventListeners.push({ event: 'preferences.clickagreetoall', listener: () => { window.cpexCmpSubscription.hideModal() } })
    window.didomiOnReady.push(() => { this.pay = new PayOkay(this.main) }) // render once didomi is ready
  }

  /**
   * Based on task FED-316: Adds requests (using pixel images) to SAS state vectors with user consent
   */
  fireConsentPixels (ignoreTimer) {
    // Add listener to trigger this again in case of any changes by the user
    window.didomiEventListeners.push({ event: 'consent.changed', listener: () => { this.fireConsentPixels(true) } })
    // if 24 hours passed, fire consent pixels
    const pixelsLastFired = window.localStorage.getItem('pixelsLastFired') || 0
    const timestamp = Date.now()
    const intervalMs = 24 * 60 * 60 * 1000
    if (timestamp - intervalMs > pixelsLastFired || ignoreTimer) {
      if (Array.isArray(this.settings.pixelList)) {
        window.__tcfapi('addEventListener', 2, (data, success) => {
          if (success === false) { return }
          if (data.vendor.consents) {
            if (Object.keys(data.vendor.consents).length > 0) {
              this.settings.pixelList.forEach((url) => {
                const finalUrl = url.replace('%CONSENT%', data.tcString)
                addPixel(finalUrl)
              })
              window.localStorage.setItem('pixelsLastFired', timestamp)
              cpexLog('CMP: Added consent pixels')
            } else {
              cpexWarn('CMP: Missing consent for calling pixels')
            }
          }
        })
      }
    } else { cpexLog('CMP: 24 hours have not yet passed, based on pixelsLastFired in local storage') }
  }

  /**
   * Based on task FED-311: Adds close button to the banner, in specific circumstances
   */
  addCloseButton () {
    if (typeof window.cpexCmpAllowCloseButton !== 'undefined' || window.location.href.indexOf('cpexCmpAllowCloseButton') !== -1) {
      const targetEl = document.getElementById('didomi-notice') || document.getElementsByClassName('didomi-popup-notice-logo-container')[0] // For both banner and popup
      if (targetEl) {
        const closeButton = document.createElement('div')
        closeButton.textContent = '×'
        closeButton.style.cssText = 'position: absolute; top: 10px; right: 10px; width: 40px; height: 40px; cursor: pointer; font-size: 20pt; text-align: center; line-height: 40px;'
        closeButton.addEventListener('click', function () { window.Didomi.notice.hide() })
        targetEl.appendChild(closeButton)
      }
    }
  }
}
